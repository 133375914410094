<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>交易查询</el-breadcrumb-item>
          <el-breadcrumb-item>账户查询</el-breadcrumb-item>
          <el-breadcrumb-item>贡献值转换日报</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="分类">
              <el-select v-model="where.accountForward">
                <el-option value="SUB" label="转换日报">转换日报</el-option>
                <el-option value="ADD" label="新增日报">新增日报</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="贡献日期">
              <el-date-picker
                  v-model="where.dates"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="贡献值">
              <el-input-number v-model="where.amount"></el-input-number>
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="listData()"
              >条件筛选
              </el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="reset()"
              >重置
              </el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
            :data="list"
            v-loading="loading"
            show-summary
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column prop="amount" label="贡献值"></el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      where: {
        accountForward:'SUB',
        current: 0, //分页，页数
        size: 0, //分页，每页大小
        amount: null, //
        dates: [],
      },
    };
  },
  created() {
    this.getBeforeDayTime();
    this.listData();
  },
  methods: {
    getBeforeDayTime(){
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      this.where.dates = [start,new Date()];
    },
    listData() {
      this.loading = true;
      this.where.current = this.currentPage;
      this.where.size = this.pageSize;
      if (this.where.dates.length != 0) {
        this.where.startTime = this.where.dates[0];
        this.where.endTime = this.where.dates[1];
      }
      this.where.accountType = 'CON_VAL';
      // this.where.accountForward = 'SUB';
      this.$post(this.$api.getAccountBillDayReport, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    reset() {
      this.where.amount = null;
      this.where.dates = [];
      this.where.startTime = null;
      this.where.endTime = null;
      this.listData();
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}

.el-image {
  height: 100px;
  width: 100px;
}

.el-select {
  width: 120px;
}

.SYSTEM,
.PROFIT_PAY {
  color: #67c23a;
}

.SHOP,
.PROFIT_PAY {
  color: #e6a23c;
}

.SERVICE_PROVIDER,
.PROFIT_BEAN {
  color: #f56c6c;
}

.PERSON,
.CON_VAL {
  color: #909399;
}

.OPERATION_CENTER,
.AD_PAY {
  color: #409eff;
}

.OPERATION_CENTER,
.AD_PAY {
  color: #a84a26;
}
</style>
<style>
.el-drawer__header span:focus {
  outline: 0 !important;
}
</style>
